import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { GoogleMap } from '@angular/google-maps';
import { OrderFormService } from '../order-form.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delivery-area-dialog',
  templateUrl: './delivery-area-dialog.component.html',
  styleUrls: ['./delivery-area-dialog.component.scss']
})
export class DeliveryAreaDialogComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) private set _googleMap(
    map: GoogleMap
  ) {
    if (map && this.check==false) {
      this.check=true;
      console.log('init polygon')
      this.polygon = new google.maps.Polygon({
        map: map.googleMap,
        paths: this.vertices.map(c => ({
          lat: c.lat,
          lng: c.lng
        })),
        strokeColor: `#caa052`,
        // editable: true,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: `#ffffb1`,
        fillOpacity: 0.35,
        // draggable: true
      });
      // google.maps.event.addListener(polygon, "click", () => {
      //   // console.log('changed',polygon.getPath().forEach) 
      //   const vertices = polygon.getPath();
      //   for (let i = 0; i < vertices.getLength(); i++) {
      //     const xy = vertices.getAt(i);
      //     console.log("Coordinate " + i + ": " + xy.lat() + "," + xy.lng())
      //   }
      // });
    }
  }
  polygon: google.maps.Polygon;
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  mapOptions: any;
  apiLoaded: Observable<boolean>;
  center: google.maps.LatLngLiteral;
  zoom = 14;
  check=false
  vertices = [];
  constructor(private dialog: MatDialog, public orderForm: OrderFormService,public dialogRef: MatDialogRef<DeliveryAreaDialogComponent>) { 
    this.center = this.orderForm.restaurant.storeCoordinate;
    this.vertices = this.orderForm.restaurant.deliveryArea;
  }

  ngOnInit(): void {
    this.mapOptions = {
      center: this.center,
      mapTypeControl: false,
      disableDefaultUI: true,
      zoomControl: true
    };
  }

}
