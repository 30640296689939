<div class="slideshow-container">

    <div class="mySlides fade" [ngClass]="{'dis-none': i != slideIndex, 'dis-block': i == slideIndex}"  *ngFor="let pic of orderService.restaurant.images; let i = index">
        <div class="numbertext">{{i+1}} / {{orderService.restaurant.images.length}}</div>
        <img [src]="getPicURL(pic)" style="width:100%">
        <div class="flexHeader">{{pic.dishName}}</div>
    </div>
    <a *ngIf="slideIndex >= 1" class="prev" (click)="plusSlides(-1)">&#10094;</a>
    <a *ngIf="slideIndex != orderService.restaurant.images.length - 1" class="next" (click)="plusSlides(1)">&#10095;</a>

</div>
<br>

<div style="text-align:center">
    <span *ngFor="let pic of orderService.restaurant.images; let i = index" class="dot" (click)="slideIndex = i" [ngClass]="{'active': i == slideIndex}"></span>
</div>

<!-- <img *ngFor="let pic of orderService.restaurant.images; let i = index" [src]="getPicURL(pic)" style="width:100%"> -->