<button class="close" mat-icon-button disabled aria-label="Button to close popup to set order details."
  mat-dialog-close>
  <mat-icon mat-dialog-close>close</mat-icon>
</button>
<div>
  <h1 class="font-weight-normal text-center m-b-8">Order Time</h1>

  <mat-divider></mat-divider>
  <!-- <pre>{{orderService.orderObject.get('deliveryAddress').value | json}}</pre> -->
  <!-- <pre>Touched?: {{orderService.orderObject.touched}}</pre> -->
  <mat-dialog-content class="w-100 m-0" style="padding: 0px !important;">
    <form [formGroup]="orderService.orderObject" #addForm="ngForm">


      <div class="text-center m-b-16">
        <p class="txt-light m-b-8">When do you want your food?</p>
        <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="isFutureOrder">
          <mat-button-toggle *ngIf="orderService.canOrderNow == true" [value]="false">Asap</mat-button-toggle>
          <mat-button-toggle *ngIf="orderService.restaurant.acceptFutureOrders==true" [value]="true">Later
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <p *ngIf="orderService.orderObject.get('orderType').value == 'Pickup'" class="text-center mt-2">Estimated Pickup
        Time: {{this.orderService.restaurant.pickupTime}}</p>
      <p *ngIf="orderService.orderObject.get('orderType').value == 'Delivery'" class="text-center mt-2">Estimated
        Delivery
        Time: {{this.orderService.restaurant.deliveryTime}}</p>


      <div class="header warning m-b-16 m-t-16"
        *ngIf="!orderService.canOrderNow && orderService.orderObject.get('orderType').value=='Pickup'">
        <p class="txt-bold">We are Closed Right Now</p>
        <p class="txt-bold">Next available Order Time is {{orderService.earliest_OrderDateTime.format("LLLL")}}</p>
      </div>
      <div class="header warning m-b-16 m-t-16"
        *ngIf="!orderService.canOrderNow && orderService.orderObject.get('orderType').value=='Delivery'">
        <p class="txt-bold">We are not Delivering Right Now</p>
        <p class="txt-bold">Next available Delivery Time is {{orderService.earliest_OrderDateTime.format("LLLL")}}</p>
      </div>
      <div class="header warning m-b-16 m-t-16"
        *ngIf="!orderService.canOrderNow && orderService.orderObject.get('orderType').value=='Doordash'">
        <p class="txt-bold">We are not Delivering Right Now</p>
        <p class="txt-bold">Next available Delivery Time is {{orderService.earliest_OrderDateTime.format("LLLL")}}</p>
      </div>


      <div *ngIf="orderService.orderObject.get('isFutureOrder').value == true" class=" m-t-16">

        <mat-form-field class="w-50-left">
          <mat-datepicker-toggle matSuffix [for]="picker" class="clock"></mat-datepicker-toggle>
          <mat-label>Order Date</mat-label>
          <input matInput [min]="orderService.earliest_OrderDateTime" [max]="maxDate" [matDatepicker]="picker"
            [matDatepickerFilter]="orderService.dateFilter" class="w-75" readonly (click)="picker.open()"
            formControlName="futureOrderDateTime" (dateChange)="dayChanged($event)">
          <mat-error>Required</mat-error>
        </mat-form-field>
        <mat-datepicker touchUi #picker></mat-datepicker>

        <mat-form-field class="w-50-right">
          <mat-label>Order Time</mat-label>
          <mat-select formControlName="futureOrderTime">
            <mat-option *ngFor="let time of orderService.orderTimes" [value]="time">{{time}}</mat-option>
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>

      </div>
    </form>
    <p class="w-full txt-center m-b-8 b-t-8" *ngIf="orderService.orderObject.get('isFutureOrder').value">
      Scheduled
      Time: {{orderService.orderObject.get('futureOrderDateTime').value.format('LLLL')}}</p>
    <p class="text-danger"
      *ngIf="orderService.orderObject.get('isFutureOrder').value == true && orderService.orderObject.errors">
      Please select a valid order time.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button (click)="checkValidDetails()" mat-raised-button color="primary" class="w-100 mt-2">Continue</button>
  </mat-dialog-actions>

</div>