import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-closed',
  templateUrl: './closed.component.html',
  styleUrls: ['./closed.component.scss']
})
export class ClosedComponent implements OnInit {
  closedMessage;
  constructor(@Inject(MAT_DIALOG_DATA) public data, ) {
    this.closedMessage = this.data.closedMessage;
  }

  ngOnInit() {
  }

}
