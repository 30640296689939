import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from "@angular/google-maps";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShellComponent } from './shell/shell.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MenuComponent } from './menu/menu.component';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { AngularFireFunctionsModule, USE_EMULATOR, } from '@angular/fire/compat/functions';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from '../environments/environment';
import { AddToOrderDialogComponent } from './add-to-order-dialog/add-to-order-dialog.component';
import { OrderFormService } from './order-form.service';
import { MenuNavigatorComponent } from './menu-navigator/menu-navigator.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutDialogComponent } from './checkout-dialog/checkout-dialog.component';
import { OrderDetailsDialogComponent } from './order-details-dialog/order-details-dialog.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { GooglePlacesDirective } from './google-place.directive';
import { LoginComponent } from './login/login.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { ClosedComponent } from './closed/closed.component';
import { OrderSubmittedDialogComponent } from './order-submitted-dialog/order-submitted-dialog.component';
import { CouponDialogComponent } from './coupon-dialog/coupon-dialog.component';
import { CouponPageComponent } from './coupon-page/coupon-page.component';
import { TosComponent } from './tos/tos.component';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { ReservationComponent } from './reservation/reservation.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MenuGroupComponent } from './menu-group/menu-group.component';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { MatBadgeModule } from '@angular/material/badge';
import { OrderTimeDetailsComponent } from './order-time-details/order-time-details.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SimpledialogComponent } from './simpledialog/simpledialog.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { DeliveryAreaDialogComponent } from './delivery-area-dialog/delivery-area-dialog.component';
import { SessionTimeoutDialogComponent } from './session-timeout-dialog/session-timeout-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    ShellComponent,
    MenuComponent,
    AddToOrderDialogComponent,
    MenuNavigatorComponent,
    CheckoutComponent,
    CheckoutDialogComponent,
    OrderDetailsDialogComponent,
    OrderHistoryComponent,
    GooglePlacesDirective,
    LoginComponent,
    LoginDialogComponent,
    ClosedComponent,
    OrderSubmittedDialogComponent,
    CouponDialogComponent,
    CouponPageComponent,
    TosComponent,
    ScrollSpyDirective,
    ReservationComponent,
    MenuGroupComponent,
    SlideshowComponent,
    OrderTimeDetailsComponent,
    PrivacyComponent,
    SimpledialogComponent,
    NotfoundComponent,
    DeliveryAreaDialogComponent,
    SessionTimeoutDialogComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatRadioModule,
    AngularFireModule.initializeApp(environment.firebaseConfig), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    GoogleMapsModule,
    AppRoutingModule,

    BrowserAnimationsModule,

    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatInputModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatMenuModule,
    MatExpansionModule,
    MatMomentDateModule,
    MatBadgeModule

  ],
  providers: [OrderFormService,
    ScreenTrackingService,
    UserTrackingService,
    // { provide: USE_EMULATOR, useValue: ['localhost', 5001] }

  ],
  entryComponents: [CouponDialogComponent, OrderSubmittedDialogComponent, LoginDialogComponent, ClosedComponent, OrderDetailsDialogComponent, AddToOrderDialogComponent, MenuNavigatorComponent, CheckoutDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
