import { Component, OnInit } from '@angular/core';
import { OrderFormService } from '../order-form.service';
import { Coupon, Restaurant } from '../Restaurant.model';

@Component({
  selector: 'app-coupon-page',
  templateUrl: './coupon-page.component.html',
  styleUrls: ['./coupon-page.component.scss']
})
export class CouponPageComponent implements OnInit {
  public couponselection = {};

  constructor(public orderService: OrderFormService) { 


  }

  ngOnInit(): void {

  }

}
