<div class="menu menuWrapper m-t-16" *ngIf="orderService.restaurant">

  <mat-card style="background-color: #fbfbfc !important">
    <img class="logo" [src]="getLogoUrl()" onerror="this.style.display = 'none'" alt="Logo">
    <div class="header">
      <div *ngIf="!orderService.restaurant.takesDelivery && !orderService.restaurant.takesDoordash">
        <p class="txt-bold">Available for Pickup Only</p>
      </div>
      <!-- <p class="">Lunch Served Everyday 11am-3pm</p> -->
      <h3>
        {{orderService.restaurant.address}}
      </h3>
      <h3>
        {{orderService.restaurant.phoneNum}}
      </h3>
      <div class="m-t-16">
        <div *ngIf="orderService.CurrentOrderStart!=''&&orderService.CurrentOrderEnd!=''" class="m-b-16">
          <p class="txt-bold">Open Hours:</p>
          <p>{{orderService.CurrentOrderStart}} - {{orderService.CurrentOrderEnd}}</p>
        </div>
        <div *ngIf="orderService.CurrentOrderStart==''||orderService.CurrentOrderEnd==''" class="m-b-16">
          <p class="txt-bold">We are closed Today.</p>

        </div>

        <div *ngIf="orderService.restaurant.takesDelivery" class="m-b-16">
          <p class="txt-bold">We Deliver!</p>
          <p class="txt-bold">$0.27 Retail Delivery Fee Included</p>
          <div *ngIf="orderService.restaurant.deliveryFees as dFees">
            <p *ngFor="let fee of dFees">{{fee.min}} - {{fee.max}} miles: {{fee.fee | currency}}</p>
          </div>
        </div>
        <div *ngIf="orderService.restaurant.takesDoordash" class="m-b-16">
          <p class="txt-bold">We Deliver Throught Doordash!</p>
          <div *ngIf="orderService.restaurant.deliveryFees as dFees">
            <p *ngFor="let fee of dFees">{{fee.min}} - {{fee.max}} miles: {{fee.fee | currency}}</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="orderService.restaurant.menuNotes" class="header">
      <div *ngFor="let note of orderService.restaurant.menuNotes">
        <p>{{note}}</p>
      </div>
    </div>

    <div class="w-80 dis-block m-auto flex-c flex-w">
      <!-- <button mat-raised-button class="m_5" color="accent" (click)="openOrderDetails()">Edit Order Details</button> -->
      <div class="space-between simple-border">
        <div class="space-between m-2">
          <p class="m-1">Order Type:</p>
          <!-- <button class="m-0 address" (click)="openOrderDetails()" mat-raised-button
            color="accent">{{orderService.orderObject.get('orderType').value}}</button> -->
          <div class="btn-group" role="group" aria-label="Basic example">
            <button [color]="orderService.orderObject.get('orderType').value =='Pickup'? 'accent':''" mat-raised-button
              class="m-0 address" (click)="openOrderDetails('Pickup')">Pickup</button>
            <button [color]="orderService.orderObject.get('orderType').value =='Delivery'? 'accent':''"
              *ngIf='orderService.restaurant.takesDelivery' mat-raised-button class="m-0 address"
              (click)="openOrderDetails('Delivery')">Delivery</button>
            <button [color]="orderService.orderObject.get('orderType').value =='Doordash'? 'accent':''"
              *ngIf='orderService.restaurant.takesDoordash' mat-raised-button class="m-0 address"
              (click)="openOrderDetails('Doordash')">Doordash</button>
          </div>
        </div>

        <div class="space-between m-1">
          <p class="m-1">Time: </p>
          <button mat-raised-button color="accent" class="m-0"
            *ngIf="orderService.orderObject.get('isFutureOrder').value == false" (click)="openTimeDetails()">Asap
          </button>
          <button mat-raised-button color="accent" class="m-0"
            *ngIf="orderService.orderObject.get('isFutureOrder').value == true" (click)="openTimeDetails()">
            {{orderService.orderObject.get('futureOrderDateTime').value.format('LLLL')}}
          </button>
        </div>
        <p class="m-2" *ngIf="orderService.orderObject.get('orderType').value == 'Delivery'">Deliver To:
          {{orderService.orderObject.get('deliveryAddress').value}},
          {{orderService.orderObject.get('aptNum').value}}</p>

        <p *ngIf="orderService.orderObject.get('orderType').value == 'Pickup'" style="font-weight: bold;"
          class="text-center mt-2">Estimated Pickup Wait
          Time: {{this.orderService.restaurant.pickupTime}}</p>
        <p *ngIf="orderService.orderObject.get('orderType').value == 'Delivery'" style="font-weight: bold;"
          class="text-center mt-2">Estimated
          Delivery Wait
          Time: {{this.orderService.restaurant.deliveryTime}}</p>
      </div>
    </div>
    <div class="header warning" *ngIf="!orderService.canOrderNow">
      <p class="txt-bold">We are Closed Right Now</p>
      <p class="txt-bold">Next available Order Time is {{orderService.earliest_OrderDateTime.format("LLLL")}}</p>
    </div>
  </mat-card>


  <div class="groupList">

    <section *ngFor="let group of orderService.groups" [id]="group.name">
      <ng-container *ngIf="renderRightGroup(group)">
        <mat-card class="groupTitle">
          <mat-card-title class="groupTitleText">{{group.name}}</mat-card-title>
          <mat-card-subtitle>{{group.description}}</mat-card-subtitle>
          <img *ngIf="group.imgURL" mat-card-image loading="lazy" [src]="group.imgURL" [alt]="'Photo of ' + group.name">
        </mat-card>

        <app-menu-group [group]="group.name"></app-menu-group>
      </ng-container>
    </section>
  </div>
  <!-- <mat-card class="m-t-16">
  <app-slideshow *ngIf="orderService.restaurant.images?.length > 0"></app-slideshow>

</mat-card> -->
</div>