<div *ngIf="orderService.restaurant.acceptsDineIn; else noDineIn">
    <mat-card *ngIf="orderService.restaurant.acceptsCheckins==false" style="margin: 1rem;">
        <h2 class="text-center">We are not accepting Checkins at this time. Please contact us for more info:
            {{this.orderService.restaurant.phoneNum}}</h2>

    </mat-card>
    <mat-card *ngIf="orderService.restaurant.acceptsCheckins" style="margin: 1rem;">
        <h2 class="text-center">Current Dine In Wait Time: {{this.orderService.restaurant.waitTime}}</h2>
        <h1 class="text-center">Others in queue: {{this.orderService.restaurant.dineInQueueLength}} </h1>
    </mat-card>
    <mat-card *ngIf="orderService.restaurant.acceptsCheckins" style="margin: 1rem;">

        <h2 class="text-center">Dine In Queue</h2>
        <p class="text-center">Submit this form to be entered into the queue. We will contact you when your table is
            ready.
        </p>
        <form [formGroup]="checkinForm" *ngIf="!checkinSubmitted">


            <mat-form-field class="w-50 px-1" appearance="fill" color="primary" [style.fontSize.px]="14">
                <mat-label>Name*</mat-label>
                <input formControlName="name" type="text" matInput placeholder="Name" class="w-100">
            </mat-form-field>

            <mat-form-field class="w-50 m-0 px-1" appearance="fill" required="true">
                <mat-label>Phone Number*</mat-label>
                <input formControlName="phone" type="tel" matInput placeholder="1234567890">
                <mat-error>Required</mat-error>
            </mat-form-field>

            <mat-form-field class="w-100 m-0 px-1" appearance="fill" required="true">
                <mat-label>Email*</mat-label>
                <input formControlName="email" type="email" matInput placeholder="Email">
                <mat-error>Required</mat-error>
            </mat-form-field>

            <mat-form-field class="w-50 m-0 px-1" appearance="fill" required="true">
                <mat-label>Guest Number*</mat-label>
                <input formControlName="guestCount" type="number" matInput>
                <mat-error>Required</mat-error>
            </mat-form-field>





            <div class="w-100">
                <mat-checkbox class="example-margin text-wrap " formControlName="agreedToTextMessages">I agree to
                    receive text messages about my table. </mat-checkbox>
                <mat-error *ngIf="checkinForm.get('agreedToTextMessages').invalid && attemptedCheckinSubmit">You must
                    agree to receive text messages. </mat-error>
            </div>

            <div class="w-100" *ngIf="checkinLoading">
                <mat-spinner class="mx-auto mt-4 mb-4"></mat-spinner>
                <p class="w-100 mx-auto text-center mt-4">Submitting Form...</p>
            </div>

            <button mat-raised-button color="primary" (click)="submitCheckin()" style="margin-top: 1rem">Submit Check In
                Form</button>

        </form>

        <div *ngIf="checkinSubmitted == true">
            Form was sucessfully submitted. ✅ <br>
        </div>


    </mat-card>


    <mat-card *ngIf="orderService.restaurant.reservations==false" style="margin: 1rem;">
        <h2 class="text-center">We are not accepting reservations at this time. </h2>

    </mat-card>
    <mat-card *ngIf="orderService.restaurant.reservations" style="margin: 1rem;">

        <h1 class="text-center">Make A Reservation</h1>
        <p class="text-center mb-4">We'll email you when you place a reservation and when the restaurant has confirmed
            your
            reservation. </p>

        <form [formGroup]="reservationForm" *ngIf="!submitted">
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Contact Info
                        </mat-panel-title>
                        <mat-icon>account_circle</mat-icon>
                    </mat-expansion-panel-header>
                    <div class="w-100 flex-wrap space-between">
                        <mat-form-field class="w-50 px-1" appearance="fill" color="primary" [style.fontSize.px]="14">
                            <mat-label>Name*</mat-label>
                            <input formControlName="name" type="text" matInput placeholder="Name" class="w-100">
                        </mat-form-field>

                        <mat-form-field class="w-50 m-0 px-1" appearance="fill" required="true">
                            <mat-label>Phone Number*</mat-label>
                            <input formControlName="phoneNum" type="tel" matInput placeholder="1234567890">
                            <mat-error>Required</mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-100 m-0 px-1" appearance="fill" required="true">
                            <mat-label>Email*</mat-label>
                            <input formControlName="email" type="email" matInput placeholder="Email">
                            <mat-error>Required</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-action-row>
                        <p>We'll contact you if there are any issues.</p>
                        <button mat-button color="primary" (click)="nextStep()">Next</button>
                    </mat-action-row>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                    <mat-expansion-panel-header class="w-100 flex-wrap space-between">
                        <mat-panel-title>
                            Reservation Time
                        </mat-panel-title>
                        <mat-icon>date_range</mat-icon>

                    </mat-expansion-panel-header>
                    <p>Select a date and time.</p>
                    <mat-form-field class="w-50-left">
                        <mat-datepicker-toggle matSuffix [for]="picker" class="clock"></mat-datepicker-toggle>
                        <mat-label>Date*</mat-label>
                        <input matInput [min]="orderService.earliest_OrderDateTime" [max]="maxDate"
                            [matDatepicker]="picker" [matDatepickerFilter]="dateFilter" class="w-75" readonly
                            (click)="picker.open()" formControlName="date">
                        <mat-error>Required</mat-error>
                    </mat-form-field>

                    <mat-datepicker touchUi #picker></mat-datepicker>

                    <mat-form-field class="w-50-right">
                        <mat-label>Time*</mat-label>
                        <mat-select formControlName="time">
                            <mat-option *ngFor="let time of openTimes" [value]="time">{{time}}</mat-option>
                        </mat-select>
                        <mat-error>Required</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Number of Guests</mat-label>
                        <mat-select formControlName="guestCount">
                            <mat-option *ngFor="let num of guestNums" [value]="num">{{num}}</mat-option>

                        </mat-select>
                        <mat-error>Required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="w-100 m-0 px-1" appearance="fill" required="true">
                        <mat-label>Comments</mat-label>
                        <textarea formControlName="comments" matInput
                            placeholder="Ex. 25th Anniversary Celebration..."></textarea>
                        <mat-error>Required</mat-error>
                    </mat-form-field>

                    <mat-action-row>
                        <button mat-button color="primary" (click)="prevStep()">Previous</button>
                        <button mat-raised-button color="primary" (click)="submitReservation()">Submit
                            Reservation</button>
                    </mat-action-row>
                </mat-expansion-panel>

            </mat-accordion>
            <div class="w-100" *ngIf="reservationLoading">
                <mat-spinner class="mx-auto mt-4 mb-4"></mat-spinner>
                <p class="w-100 mx-auto text-center mt-4">Submitting Reservation...</p>
            </div>


        </form>
        <div *ngIf="submitted == true">
            Your reservation has been submitted. ✅ <br>
            Check your email to ensure your reservation is confirmed. If not confirmed within an opening day, please
            call
            the restaurant to ensure your reservation.
        </div>


    </mat-card>

</div>

<ng-template #noDineIn>
    <mat-card style="margin: 1rem;">
        <h2 class="text-center">We are currently offerintg To Go service only. Please contact us for more information. </h2>
    </mat-card>
</ng-template>