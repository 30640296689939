<div class="tos">
  <h2 class="text-center mt-5">
    Privacy Policy for online ordering,
    Develop Denver LLC DBA Inhouse
    Orders
  </h2>
  <hr />
  <h4 class="text-center">
  </h4>
  <!-- <h4 class="text-center">Public Phone: {{ orderService.restaurant.phoneNum }}</h4> -->
  <hr />
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    Develop Denver, LLC built the Pearl Wok Chinese Restaurant app as a Free
    app. This SERVICE is provided by Develop Denver, LLC at no cost and is
    intended for use as is.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    This page is used to inform visitors regarding our policies with the
    collection, use, and disclosure of Personal Information if anyone decided to
    use our Service.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    If you choose to use our Service, then you agree to the collection and use
    of information in relation to this policy. The Personal Information that we
    collect is used for providing and improving the Service. We will not use or
    share your information with anyone except as described in this Privacy
    Policy.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    The terms used in this Privacy Policy have the same meanings as in our Terms
    and Conditions, which is accessible at Pearl Wok Chinese Restaurant unless
    otherwise defined in this Privacy Policy.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <strong
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: bolder;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
      >Information Collection and Use</strong
    >
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    For a better experience, while using our Service, we may require you to
    provide us with certain personally identifiable information, including but
    not limited to First Name, Last Name, Phone Number, Address, zip code, email
    address, ip address. The information that we request will be retained by us
    and used as described in this privacy policy.
  </p>
  <div
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <p
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: 400;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        margin-top: 1rem;
        margin-bottom: 1rem;
        text-align: left !important;
      "
    >
      The app does use third party services that may collect information used to
      identify you.
    </p>
    <p
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: 400;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        margin-top: 1rem;
        margin-bottom: 1rem;
        text-align: left !important;
      "
    >
      Link to privacy policy of third party service providers used by the app
    </p>
    <ul
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: 400;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        margin-top: 0px;
        margin-bottom: 1rem;
        text-align: left !important;
      "
    >
      <li
        style="
          box-sizing: border-box;
          font-family: var(--main-font);
          font-weight: 400;
          color: rgb(34, 34, 34);
          -webkit-font-smoothing: antialiased;
          text-align: left !important;
        "
      >
        <a
          href="https://www.google.com/policies/privacy/"
          rel="nofollow"
          style="
            box-sizing: border-box;
            font-family: var(--main-font);
            font-weight: 400;
            color: rgb(0, 156, 156);
            -webkit-font-smoothing: antialiased;
            text-decoration: none;
            background-color: transparent;
            transition: all 0.2s linear 0s;
            text-align: left !important;
          "
          target="_blank"
          >Google Play Services</a
        >
      </li>
      <li
        style="
          box-sizing: border-box;
          font-family: var(--main-font);
          font-weight: 400;
          color: rgb(34, 34, 34);
          -webkit-font-smoothing: antialiased;
          text-align: left !important;
        "
      >
        <a
          href="https://support.google.com/admob/answer/6128543?hl=en"
          rel="nofollow"
          style="
            box-sizing: border-box;
            font-family: var(--main-font);
            font-weight: 400;
            color: rgb(0, 156, 156);
            -webkit-font-smoothing: antialiased;
            text-decoration: none;
            background-color: transparent;
            transition: all 0.2s linear 0s;
            text-align: left !important;
          "
          target="_blank"
          >AdMob</a
        >
      </li>
      <li
        style="
          box-sizing: border-box;
          font-family: var(--main-font);
          font-weight: 400;
          color: rgb(34, 34, 34);
          -webkit-font-smoothing: antialiased;
          text-align: left !important;
        "
      >
        <a
          href="https://firebase.google.com/policies/analytics"
          rel="nofollow"
          style="
            box-sizing: border-box;
            font-family: var(--main-font);
            font-weight: 400;
            color: rgb(0, 156, 156);
            -webkit-font-smoothing: antialiased;
            text-decoration: none;
            background-color: transparent;
            transition: all 0.2s linear 0s;
            text-align: left !important;
          "
          target="_blank"
          >Google Analytics for Firebase</a
        >
      </li>
      <li
        style="
          box-sizing: border-box;
          font-family: var(--main-font);
          font-weight: 400;
          color: rgb(34, 34, 34);
          -webkit-font-smoothing: antialiased;
          text-align: left !important;
        "
      >
        <a
          href="https://firebase.google.com/support/privacy/"
          rel="nofollow"
          style="
            box-sizing: border-box;
            font-family: var(--main-font);
            font-weight: 400;
            color: rgb(0, 156, 156);
            -webkit-font-smoothing: antialiased;
            text-decoration: none;
            background-color: transparent;
            transition: all 0.2s linear 0s;
            text-align: left !important;
          "
          target="_blank"
          >Firebase Crashlytics</a
        >
      </li>
      <li
        style="
          box-sizing: border-box;
          font-family: var(--main-font);
          font-weight: 400;
          color: rgb(34, 34, 34);
          -webkit-font-smoothing: antialiased;
          text-align: left !important;
        "
      >
        <a
          href="https://www.facebook.com/about/privacy/update/printable"
          rel="nofollow"
          style="
            box-sizing: border-box;
            font-family: var(--main-font);
            font-weight: 400;
            color: rgb(0, 156, 156);
            -webkit-font-smoothing: antialiased;
            text-decoration: none;
            background-color: transparent;
            transition: all 0.2s linear 0s;
            text-align: left !important;
          "
          target="_blank"
          >Facebook</a
        >
      </li>
      <li
        style="
          box-sizing: border-box;
          font-family: var(--main-font);
          font-weight: 400;
          color: rgb(34, 34, 34);
          -webkit-font-smoothing: antialiased;
          text-align: left !important;
        "
      >
        <a
          href="https://stripe.com/privacy"
          rel="nofollow"
          style="
            box-sizing: border-box;
            font-family: var(--main-font);
            font-weight: 400;
            color: rgb(0, 156, 156);
            -webkit-font-smoothing: antialiased;
            text-decoration: none;
            background-color: transparent;
            transition: all 0.2s linear 0s;
            text-align: left !important;
          "
          target="_blank"
          >Stripe</a
        >
      </li>
    </ul>
  </div>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <strong
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: bolder;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
      >Log Data</strong
    >
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    We want to inform you that whenever you use our Service, in a case of an
    error in the app we collect data and information (through third party
    products) on your phone called Log Data. This Log Data may include
    information such as your device Internet Protocol (&ldquo;IP&rdquo;)
    address, device name, operating system version, the configuration of the app
    when utilizing our Service, the time and date of your use of the Service,
    and other statistics.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <strong
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: bolder;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
      >Cookies</strong
    >
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    Cookies are files with a small amount of data that are commonly used as
    anonymous unique identifiers. These are sent to your browser from the
    websites that you visit and are stored on your device&apos;s internal
    memory.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    This Service does not use these &ldquo;cookies&rdquo; explicitly. However,
    the app may use third party code and libraries that use
    &ldquo;cookies&rdquo; to collect information and improve their services. You
    have the option to either accept or refuse these cookies and know when a
    cookie is being sent to your device. If you choose to refuse our cookies,
    you may not be able to use some portions of this Service.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <strong
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: bolder;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
      >Service Providers</strong
    >
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    We may employ third-party companies and individuals due to the following
    reasons:
  </p>
  <ul
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 0px;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <li
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: 400;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
    >
      To facilitate our Service;
    </li>
    <li
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: 400;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
    >
      To provide the Service on our behalf;
    </li>
    <li
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: 400;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
    >
      To perform Service-related services; or
    </li>
    <li
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: 400;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
    >
      To assist us in analyzing how our Service is used.
    </li>
  </ul>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    We want to inform users of this Service that these third parties have access
    to your Personal Information. The reason is to perform the tasks assigned to
    them on our behalf. However, they are obligated not to disclose or use the
    information for any other purpose.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <strong
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: bolder;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
      >Security</strong
    >
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    We value your trust in providing us your Personal Information, thus we are
    striving to use commercially acceptable means of protecting it. But remember
    that no method of transmission over the internet, or method of electronic
    storage is 100% secure and reliable, and we cannot guarantee its absolute
    security.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <strong
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: bolder;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
      >Links to Other Sites</strong
    >
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    This Service may contain links to other sites. If you click on a third-party
    link, you will be directed to that site. Note that these external sites are
    not operated by us. Therefore, we strongly advise you to review the Privacy
    Policy of these websites. We have no control over and assume no
    responsibility for the content, privacy policies, or practices of any
    third-party sites or services.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <strong
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: bolder;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
      >Children&rsquo;s Privacy</strong
    >
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    These Services do not address anyone under the age of 13. We do not
    knowingly collect personally identifiable information from children under 13
    years of age. In the case we discover that a child under 13 has provided us
    with personal information, we immediately delete this from our servers. If
    you are a parent or guardian and you are aware that your child has provided
    us with personal information, please contact us so that we will be able to
    do necessary actions.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <strong
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: bolder;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
      >Changes to This Privacy Policy</strong
    >
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    We may update our Privacy Policy from time to time. Thus, you are advised to
    review this page periodically for any changes. We will notify you of any
    changes by posting the new Privacy Policy on this page.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    This policy is effective as of 2019-01-01
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    <strong
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: bolder;
        color: rgb(34, 34, 34);
        -webkit-font-smoothing: antialiased;
        text-align: left !important;
      "
      >Contact Us</strong
    >
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    If you have any questions or suggestions about our Privacy Policy, do not
    hesitate to contact us at info@inhouseorders.io.
  </p>
  <p
    style="
      box-sizing: border-box;
      font-family: Oxygen;
      font-weight: 400;
      color: rgb(34, 34, 34);
      -webkit-font-smoothing: antialiased;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-variant-ligatures: normal;
      font-variant-caps: normal;
      letter-spacing: normal;
      orphans: 2;
      text-indent: 0px;
      text-transform: none;
      white-space: normal;
      widows: 2;
      word-spacing: 0px;
      -webkit-text-stroke-width: 0px;
      background-color: rgb(255, 255, 255);
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    "
  >
    This privacy policy page was created at&nbsp;<a
      href="https://privacypolicytemplate.net/"
      rel="nofollow"
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: 400;
        color: rgb(0, 156, 156);
        -webkit-font-smoothing: antialiased;
        text-decoration: none;
        background-color: transparent;
        transition: all 0.2s linear 0s;
        text-align: left !important;
      "
      target="_blank"
      >privacypolicytemplate.net&nbsp;</a
    >and modified/generated by&nbsp;<a
      href="https://app-privacy-policy-generator.nisrulz.com/"
      rel="nofollow"
      style="
        box-sizing: border-box;
        font-family: var(--main-font);
        font-weight: 400;
        color: rgb(0, 156, 156);
        -webkit-font-smoothing: antialiased;
        text-decoration: none;
        background-color: transparent;
        transition: all 0.2s linear 0s;
        text-align: left !important;
      "
      target="_blank"
      >App Privacy Policy Generator</a
    >
  </p>
</div>
