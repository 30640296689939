import { Component, OnInit } from '@angular/core';
import { OrderFormService } from '../order-form.service';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AddToOrderDialogComponent } from '../add-to-order-dialog/add-to-order-dialog.component';

@Component({
  selector: 'app-menu-navigator',
  templateUrl: './menu-navigator.component.html',
  styleUrls: ['./menu-navigator.component.scss']
})
export class MenuNavigatorComponent implements OnInit {

  public pre_options=[];
  itemControl = new FormControl();
  filteredOptions: Observable<string[]>;
  options: string[]=[];

  constructor(public dialog: MatDialog, public orderService: OrderFormService, public dialogRef: MatDialogRef<MenuNavigatorComponent>) {
    // console.log('menuList: ',this.orderService.menuList);
    for (let index = 0; index < this.orderService.menuList.length; index++) {
      const element = this.orderService.menuList[index];
      this.pre_options.push(element);
      this.options.push(element.name);
    }
    //this.options=this.cartService.finalMenuArray
    // console.log(this.pre_options)
   }

  ngOnInit() {
    this.filteredOptions = this.itemControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  getItem(itemName){
    for (let  index= 0; index < this.pre_options.length; index++) {
        if (this.pre_options[index].name==itemName) {
          return this.pre_options[index];
        }
    }
  }

  openDialog(menuItem): void {
    const dialogNow = this.dialog.open(AddToOrderDialogComponent, {
      autoFocus: false,
      width: '400px',
      maxWidth: '700px',


      data: { menuItem }

    });
  }
  goToGroup(g) {

    this.dialogRef.close();

    document.getElementById(g).scrollIntoView(true);
    // console.log(document.getElementById(g).parentElement.parentElement.parentElement);
    document.getElementById(g).parentElement.parentElement.parentElement.scrollTop -= 100;
  }

}
