import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { OrderFormService } from '../order-form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { OrderDetailsDialogComponent } from '../order-details-dialog/order-details-dialog.component';
import { FormControl } from '@angular/forms';
import { trigger, style, animate, transition, state, group } from '@angular/animations';
import { AddToOrderDialogComponent } from '../add-to-order-dialog/add-to-order-dialog.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { OverlayContainer } from '@angular/cdk/overlay';


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css'],

  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 0 })),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),

        group([
          animate(150, style({ height: 0 })),
          animate('200ms ease-in-out', style({ 'opacity': '0' }))
        ])

      ]),
      transition(':enter', [
        style({ height: '0', opacity: 0 }),

        group([
          animate(150, style({ height: '*' })),
          animate('200ms ease-in-out', style({ 'opacity': '1' }))
        ])

      ])
    ])
  ]
})
export class ShellComponent {

  constructor(private overlayContainer: OverlayContainer, public activatedRoute: ActivatedRoute, analytics: AngularFireAnalytics, public afAuth: AngularFireAuth, public dialog: MatDialog, public router: Router, public orderService: OrderFormService, private breakpointObserver: BreakpointObserver) {
    analytics.logEvent('website_load');
  }

  open = false;
  searching = false;
  currentSection = '';
  public pre_options = [];
  itemControl = new FormControl();
  filteredOptions: Observable<string[]>;
  options: string[] = [];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isMedium$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 450px)').pipe(
    map(result => result.matches),
    shareReplay()
  );




  ngOnInit() {
    // console.log('route', this.activatedRoute.snapshot.paramMap.get("restaurantId"))
    this.orderService.getAndSetRestaurant(this.activatedRoute.snapshot.paramMap.get("restaurantId"))
    this.currentSection = 'Appetizers'
    // console.log('groups', this.orderService.groups)

    // APP AUTO SIGN IN
    // this.anonSignin();  
    this.filteredOptions = this.itemControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  openDialog(menuItem): void {
    const dialogNow = this.dialog.open(AddToOrderDialogComponent, {
      autoFocus: false,
      width: '400px',
      maxWidth: '95vw',
      data: { menuItem }

    });
  }

  getItem(itemName) {
    for (let index = 0; index < this.pre_options.length; index++) {
      if (this.pre_options[index].name == itemName) {
        return this.pre_options[index];
      }
    }
  }
  getbgPic() {
    return { 'background-image': `url(./assets/images/${this.orderService.restaurant?.restaurantID}/bg.jpg)` }
  }

  openOrderDetails() {
    const dialogRef = this.dialog.open(OrderDetailsDialogComponent, {
      width: '100%',
      maxWidth: '700px',
      autoFocus: false,
    });
  }
  clearSearch() {
    this.itemControl.setValue('');
  }

  async anonSignin() {
    const credential = await this.afAuth.signInAnonymously();
  }

  loadSearch() {
    if (this.pre_options.length == 0 && this.options.length == 0) {
      for (let i = 0; i < this.orderService.menuList.length; i++) {
        //  console.log('this.orderService.menuList[i];',this.orderService.menuList[i])
        const element = this.orderService.menuList[i];
        this.pre_options.push(element);
        this.options.push(element.name);
      }
      // console.log ('this.options',this.options)
      // console.log(this.pre_options)
    }

  }
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
    // console.log("this.currentSection:", this.currentSection)
    if (!this.currentSection) {
      this.currentSection = 'Appetizers'
    }
  }
  scrollToGroup(g) {
    document.getElementById(g).scrollIntoView(true);
    document.querySelector('.wrapper').scrollBy(0, -100)
  }
}