import { Directive, Injectable, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[scrollSpy]'
})
export class ScrollSpyDirective {
    @Input() public spiedTags = [];
    @Output() public sectionChange = new EventEmitter<string>();
    private currentSection: string;

    constructor(private _el: ElementRef) { }

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        let currentSection: string;
        let scrollOffset = 181;
        const children = this._el.nativeElement.children;
        // console.log('scroll detected');
        const scrollTop = event.target.scrollTop;
        const parentOffset = event.target.offsetTop;
        for (let i = 0; i < children.length; i++) { 
            const groups = children[i];
            // // console.log('groups.className: ', groups.className)
            if (groups.classList.contains("groups")) {
                // console.log("inthe group")
                const groups_children = groups.children
                // console.log('groups_children', groups_children);
                for (let j = 0; j < groups_children.length; j++) {
                    const menu = groups_children[j]
                    // console.log("menu.tagName", menu.tagName)
                    if (menu.tagName == 'APP-MENU') {
                        // console.log('in the menu')
                        const menu_children = menu.children[0].children;
                        // console.log('menu_children', menu_children)
                        for (let x = 0; x < menu_children.length; x++) {
                            const section = menu_children[x];
                            // console.log('grouplist.tagName:', section.tagName)
                            if (section.tagName == "DIV") {
                                const section_childrent = section.children;
                                // console.log('section_childrent', section_childrent);
                                for (let x = 0; x < section_childrent.length; x++) {
                                    const element = section_childrent[x];
                                    if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
                                        if ((element.offsetTop - parentOffset - scrollOffset) > 0 && (element.offsetTop - parentOffset - scrollOffset) <= scrollTop) {
                                            // console.log('element.tagName', element.tagName);
                                            // console.log('element.id', element.id);
                                            currentSection = element.id;

                                        }
                                    }

                                }

                            }

                        }
                    }

                }
            }



        }
        if (currentSection !== this.currentSection) {
            this.currentSection = currentSection;
            this.sectionChange.emit(this.currentSection);
        }
    }


}