import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-order-submitted-dialog',
  templateUrl: './order-submitted-dialog.component.html',
  styleUrls: ['./order-submitted-dialog.component.scss']
})
export class OrderSubmittedDialogComponent implements OnInit {
  submittedMessage;
  constructor(@Inject(MAT_DIALOG_DATA) public data, ) {
    this.submittedMessage = data.submittedMessage;
  }

  ngOnInit() {
  }

}
