<form (ngSubmit)="submitCharge($event)" class="checkout">

  <mat-dialog-content class="mat-typography">

    <button class="close pointer" mat-icon-button disabled aria-label="Button to close checkout dialog."
      mat-dialog-close>
      <mat-icon mat-dialog-close class="pointer">close</mat-icon>
    </button>
    <h3 class="text-center mt-2 mb-1">Make Payment</h3>
    <div>
      <div class="total">
        <p>Total: </p>
        <p>{{orderService.orderObject.get('total').value | currency}}</p>
      </div>
      <p class="upperHint text-center">Pay with a credit card</p>

      <div #cardElement>
        <!-- A Stripe Element will be inserted here. -->
      </div>


      <!-- Used to display Element errors. -->
      <p class="text-danger">{{ cardErrors }}</p>



      <mat-spinner class="spinner" *ngIf="this.loading"></mat-spinner>
      <h3 class="upperHint" style="font-weight: bold;" *ngIf="this.loading">Processing Order...</h3>
      <h3 class="upperHint" style="font-weight: bold;" *ngIf="this.processOverTime">Taking longer than usual due to poor
        network connection, please
        be patient....</h3>

      <!-- <p class="upperHint text-center" *ngIf="canUseWallet">Or pay with a digital wallet</p>
    <div #payElement>

    </div> -->

    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="submit" class="w-100 m-t-16" color="primary" [disabled]="loading" mat-raised-button>Place Order for
      {{
      orderService.orderObject.get('total').value | currency}}</button>
  </mat-dialog-actions>
</form>