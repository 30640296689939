<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content class="mat-typography">
    {{data.message}}
    <p *ngIf = 'data.errorMessage'>
      {{data.errorMessage}}
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
