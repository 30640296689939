import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable, timer } from 'rxjs';
import { switchMap, startWith, tap } from 'rxjs/operators';
import { SessionTimeoutDialogComponent } from './session-timeout-dialog/session-timeout-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class TimeoutService {
  private timeoutMinutes = 20; // 20 minutes timeout
  private activity$: Subject<void> = new Subject();

  constructor(private dialog: MatDialog) { }

  initializeTimeout() {
    this.activity$.pipe(
      startWith(''),
      // Restart the timer on every activity
      switchMap(() => timer(this.timeoutMinutes * 60 * 1000)),
      tap(() => this.openDialogAndReload())
    ).subscribe();
  }

  userActivity() {
    this.activity$.next();
  }

  private openDialogAndReload() {
    const dialogRef = this.dialog.open(SessionTimeoutDialogComponent);
    dialogRef.afterClosed().subscribe(() => {
      window.location.reload();
    });
  }
}
