import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss']
})
export class SessionTimeoutDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
