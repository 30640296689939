import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { OrderFormService } from '../order-form.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderTimeDetailsComponent } from '../order-time-details/order-time-details.component';
import { DeliveryAreaDialogComponent } from '../delivery-area-dialog/delivery-area-dialog.component';



@Component({
  selector: 'app-order-details-dialog',
  templateUrl: './order-details-dialog.component.html',
  styleUrls: ['./order-details-dialog.component.scss'],

})
export class OrderDetailsDialogComponent implements OnInit, AfterViewInit, OnDestroy {


  // cannotOrderNow = false;
  // addressInput;
  specificAddress = ''
  date = moment();
  minTime = '';
  maxTime = '';
  minDate = moment.tz(this.orderService.restaurant.timezone);
  maxDate = moment().tz(this.orderService.restaurant.timezone);
  loading = true;
  orderTimes = [];
  message = '';
  OrderTimerMap = [];
  // dateFilter = date => !this.orderService.closeDays.includes(date.day());
  futureOrderTime = new Subject<string>();
  public futureOrderTime$ = this.futureOrderTime.asObservable() //Has a $ 

  public futureOrderTime_sub;

  constructor(
    private _snackBar: MatSnackBar,
    private dialog: MatDialog, public afAuth: AngularFireAuth, public orderService: OrderFormService,
    private cd: ChangeDetectorRef, public dialogRef: MatDialogRef<OrderDetailsDialogComponent>) {



    this.futureOrderTime_sub = this.futureOrderTime$.subscribe(val => {

      if (val) {
        let stringStarter = this.orderService.orderObject.get('futureOrderDateTime').value.format('YYYY MM DD') + ' ';
        let updatedDateTime: moment.Moment = moment(stringStarter + val, 'YYYY MM DD hh:mm a')

        this.orderService.orderObject.patchValue({ 'futureOrderDateTime': updatedDateTime });
      }


    });
    this.maxDate = moment.tz(this.orderService.restaurant.timezone).add(5, 'days');

    this.orderService.orderObject.get('deliveryAddress').valueChanges.subscribe(val => {
      this.cd.detectChanges();
    });

    this.orderService.orderObject.get('deliveryAddress').statusChanges.subscribe(val => {
      // console.log('status change', val);
      this.cd.detectChanges();
    })
  }


  ngOnInit() {
  }
  ngOnDestroy() {
    this.futureOrderTime_sub.unsubscribe();
  }

  getMaxMiles() {
    return Math.max.apply(Math, this.orderService.restaurant.deliveryFees.map(function (o) { return o.max; }));

  }

  openDeliveryAreaDialog(){
    const dialogRef = this.dialog.open(DeliveryAreaDialogComponent, {
      width: '100%',
      height: '100%',
      disableClose: true,      
    });
  }

  dayChanged(val) {

    let orderTimes = this.orderService.getTimeList(val.value)
    this.orderService.orderObject.patchValue({ 'futureOrderTime': orderTimes[0] });
  }

  ngAfterViewInit() {
    // console.log('orderTimeMap', this.orderService.orderTimeMap[this.minDate.getDay()]);

  }
  updateAddress(e) {
    this.orderService.orderObject.patchValue({ 'deliveryAddress': e });
  }

  handleSearchError(e) {
    this.specificAddress = e
  }
  openLoginDialog() {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      width: '90vw',
      maxWidth: '400px',
      autoFocus: false,
    });
  }

  clearAddress() {
    this.orderService.orderObject.patchValue({ 'deliveryAddress': '' })
  }

  checkValidDetails() {
    if (this.orderService.orderObject.get('deliveryAddress').invalid || this.orderService.orderObject.get('futureOrderDateTime').invalid) {
      if (this.orderService.orderObject.get('deliveryAddress').invalid) {
        this._snackBar.open('You have not selected a valid delivery address', 'Ok', {});
      }

      return;
    }
    else {
      const dialogRef2 = this.dialog.open(OrderTimeDetailsComponent, {
        width: '100%',
        maxWidth: '700px',
        autoFocus: false,
      });
      this.dialogRef.close();

    }
  }


}
