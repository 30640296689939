<section class="menu-section">
  <mat-card class="home-card" *ngFor="let menuItem of returnGroupArray(group)" (click)="openItem(menuItem)">
    <mat-card-content class="home-card-content">
      <p class="mb-0 w-100"><b>{{menuItem.name}}</b><span class="notAvailable" *ngIf='!menuItem.available'> Not
          Available</span></p>
      <div style="display:  flex; justify-content: space-between; width: 100%; margin-top: 0.5rem;">
        <div>
          <p *ngIf="menuItem.description" class="description">
            {{menuItem.description}}
          </p>
          <p class="prices accent-color price mt-0" *ngFor="let price of menuItem.basePrices">
            <span *ngIf="price.type != 'Regular'">{{price.type}}:</span> {{price.price | currency}}
          </p>
        </div>

        <img *ngIf="menuItem.imgURL" loading='lazy' width="125px" height="125px" class="menuItemImage"
          [src]="menuItem.imgURL" alt="Photo of food">
      </div>


    </mat-card-content>
  </mat-card>
</section>