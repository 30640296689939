import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TimeoutService } from './timeout.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ordering-Template';
  constructor(router: Router, private timeoutService: TimeoutService) {
    router.events.subscribe((event: NavigationEnd) => {
      document.querySelector('.wrapper') ? document.querySelector('.wrapper').scrollTop = 0 : ''
    })
    this.timeoutService.initializeTimeout();
  }
  ngOnInit(): void {
    // console.log('testing...')
  }
  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:click')
  @HostListener('document:scroll')
  onUserActivity() {
    this.timeoutService.userActivity();
  }

}
