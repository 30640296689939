import { Component, OnInit } from '@angular/core';
import { OrderFormService } from '../order-form.service';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss']
})
export class TosComponent implements OnInit {

  constructor(public orderService: OrderFormService) { }

  ngOnInit(): void {
  }

}
