import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AddToOrderDialogComponent } from '../add-to-order-dialog/add-to-order-dialog.component';
import { Title, Meta } from '@angular/platform-browser';
import { OrderFormService } from '../order-form.service';
import { OrderDetailsDialogComponent } from '../order-details-dialog/order-details-dialog.component';
import { ClosedComponent } from "../closed/closed.component";
import { ActivatedRoute, Router } from '@angular/router';
import { MatAccordion } from '@angular/material/expansion';
import { OrderTimeDetailsComponent } from '../order-time-details/order-time-details.component';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})


export class MenuComponent implements OnInit, AfterViewInit {
  @ViewChild(MatAccordion) menu: MatAccordion;

  active;
  closedMessage;
  currentSection = 'Appetizer';
  isFirst;

  expanded = false;
  constructor(@Inject(DOCUMENT) private _document: HTMLDocument, private activatedRoute: ActivatedRoute, private titleService: Title, private meta: Meta, public router: Router, public orderService: OrderFormService, private afs: AngularFirestore, public dialog: MatDialog) {
    if (this.orderService.restaurant.colors?.main) {
      document.documentElement.style.setProperty('--main-bg-color', this.orderService.restaurant.colors.main);
      document.documentElement.style.setProperty('--accent-bg-color', this.orderService.restaurant.colors.accent);
      document.documentElement.style.setProperty('--accent-text-color', this.orderService.restaurant.colors.text);

    }

  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  ngOnInit() {
    let paramValue
    this.activatedRoute.queryParams.subscribe(params => {
      paramValue = params['rwg_token']; // Replace 'paramName' with the actual name of your query parameter
      // console.log('Query Parameter Value:', paramValue);
      if (paramValue) {
        this.orderService.orderObject.patchValue({ 'rwg_token': paramValue });
      }

    });
    let displayName = this.orderService.restaurant.displayName
    let desc = `Menu, hours, photos, and more for ${displayName} located at ${this.orderService.restaurant.address} offering lunch Specials, Dinner. Order online from ${displayName} on our official website. Takeout  ...`;
    let title = `${displayName} - Order Online | Chinese & Asian Food Near Me`;
    let image = `assets/images/${this.orderService.restaurant.restaurantID}/bg.jpg`;
    let url = this.orderService.restaurant.website;

    this.titleService.setTitle(title);
    this.meta.addTags([

      { name: 'description', content: 'Article Description' },
      { name: 'robots', content: 'index,follow' },
      { property: 'og:title', content: title },
      { property: 'og:description', content: desc },
      { property: 'og:image', content: image },
      // { property: 'og:url', content: url },
      { property: 'description', content: desc },
      { property: 'geo.region', content: 'US-CO' },

    ]);
    // document.getElementById('appFavicon').setAttribute('href',);
    this._document.getElementById('favicon').setAttribute('href', `./assets/images/${this.orderService.restaurant.restaurantID}/favicon.png`);


  }
  getLogoUrl() {
    // console.log("image Link ",this.orderService.restaurant.imageLinks.logo)
    return this.orderService.restaurant?.imageLinks.logo;
  }
  invalidImage(g) {
    // console.log(g + 'img')
    console.log(document.getElementById(g + 'img'))
    // document.getElementById(g + 'img').remove()
  }
  getPicURL(g) {
    return `../../assets/images/${this.orderService.restaurant.restaurantID}/${g}.jpg`
  }

  ifPicExists(g) {
    return `../../assets/images/${this.orderService.restaurant.restaurantID}/${g}.jpg`
  }

  handleImageError(group) {
    document.getElementById(group + '_header').style.display = 'flex';
    document.getElementById(group + '_pic').style.display = 'none';
  }

  ngAfterViewInit() {


    this.afs.doc('restaurants/' + this.orderService.restaurant.restaurantID).valueChanges().subscribe((doc: any) => {

      if (doc.acceptingOrders == false) {
        this.active = false;
        this.closedMessage = doc.closedMessage;
        const dialogRef = this.dialog.open(ClosedComponent, {
          width: '90vw',
          maxWidth: '700px',
          // disableClose: true,
          data: { closedMessage: doc.closedMessage }
        });
      }
      else {
        this.active = true;
        this.closedMessage = '';
      }
    });


  }
  openOrderDetails(orderType: string) {
    this.orderService.orderObject.patchValue({ 'orderType': orderType })
    const dialogRef = this.dialog.open(OrderDetailsDialogComponent, {
      width: '100%',
      maxWidth: '700px',
      autoFocus: false,
    });
  }
  openTimeDetails() {
    console.log('opening time...')
    const dialogRef2 = this.dialog.open(OrderTimeDetailsComponent, {
      width: '100%',
      maxWidth: '700px',
      autoFocus: false,
    });
  }

  openClosedDialog(closedMessage) {
    const dialogRef = this.dialog.open(ClosedComponent, {
      disableClose: true,
      data: { closedMessage }
    });
  }



  renderRightGroup(g) {
    if (!g.name.includes('Lunch') && !g.name.includes('Breakfast')) {
      return true;
    } else if (g.name.includes('Lunch') && this.orderService.getCanOrderLunch()) {
      return true;
    } else if (g.name.includes('Breakfast') && this.orderService.getCanOrderBreakfast()) {
      return true;
    }
    else {
      return false;
    }
  }

  scrollToGroup(g) {
    document.getElementById(g).scrollIntoView(true);
    // console.log(document.getElementById(g).parentElement.parentElement.parentElement);
    document.getElementById(g).parentElement.parentElement.parentElement.scrollTop -= 100;

  }

}
