<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav mat-elevation-z1" fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <mat-toolbar [routerLink]="this.activatedRoute.snapshot.paramMap.get('restaurantId')"
      routerLinkActive="router-link-active"><span>{{orderService.restaurant?.displayName }}</span>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/' + this.activatedRoute.snapshot.paramMap.get('restaurantId')"
        routerLinkActive="activeRoute" [routerLinkActiveOptions]="{ exact: true }">Menu</a>
      <a mat-list-item routerLink="coupons" [routerLinkActive]="['activeRoute']">Coupons</a>
      <a mat-list-item routerLink="checkout" [routerLinkActive]="['activeRoute']">Checkout&nbsp;<span
          *ngIf="orderService.cartLength > 0">({{orderService.cartLength}})</span></a>
      <a mat-list-item routerLink="orderHistory" [routerLinkActive]="['activeRoute']">Order History</a>
      <a *ngIf="orderService.restaurant?.reservations" mat-list-item routerLink="reservations"
        [routerLinkActive]="['activeRoute']">Make Reservation</a>

      <mat-divider></mat-divider>

    </mat-nav-list>
    <div class="text-center" style="padding: 0.5rem;">
      <a class="smallLink" style="margin-bottom: 3px;" href="https://inhouseorders.io/">Powered By Inhouse Orders ❤️</a>
      <a class="smallLink"
        [routerLink]="'/' +this.activatedRoute.snapshot.paramMap.get('restaurantId')+'/Terms-Of-Service'"
        mat-list-item>Terms of Service</a>
      <a class="smallLink"
        [routerLink]="'/' +this.activatedRoute.snapshot.paramMap.get('restaurantId')+'/privacy-policy'"
        mat-list-item>Privacy Policy</a>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="bg #tabsContentRef" fullscreen [ngStyle]="getbgPic()" *ngIf="orderService.restaurant">
    <div class="wrapper" scrollSpy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
      <mat-toolbar color="primary" style="overflow: hidden;">
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>

        <button *ngIf="router.url.includes('checkout')" routerLink="./" mat-fab color="primary"
          aria-label="Back Button">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-button [routerLink]="'/' + this.activatedRoute.snapshot.paramMap.get('restaurantId')">
          {{orderService.restaurant?.displayName}} Online Ordering</button>
      </mat-toolbar>
      <mat-toolbar *ngIf="orderService.restaurant && router.url === ('/' + orderService.restaurant.restaurantID)"
        color="primary" class="search-toolbar">

        <mat-toolbar-row>
          <div class="w-100 flex-sb searchBar">
            <button mat-flat-button color="accent" inline='true' mat-button [matMenuTriggerFor]="menu">
              {{currentSection}}<mat-icon>expand_more</mat-icon>
            </button>
            <button type="button" mat-icon-button (click)="searching = !searching">
              <mat-icon aria-label="Side nav toggle icon">search</mat-icon>
            </button>
          </div>
          <mat-menu yPosition="above" #menu="matMenu">
            <button mat-menu-item *ngFor="let group of this.orderService.groups" (click)="scrollToGroup(group.name)">
              {{group.name}}
            </button>
          </mat-menu>
        </mat-toolbar-row>

      </mat-toolbar>

      <mat-toolbar color="primary" *ngIf="searching" [@slideInOut]>
        <mat-form-field [style.fontSize.px]="12" class="w-100">
          <input (focus)="loadSearch()" type="search" placeholder="Search for Item..." aria-label="Number" matInput
            [formControl]="itemControl" matInput [matAutocomplete]="auto">
          <button mat-button matSuffix mat-icon-button aria-label="Clear" ([mat-dialog-close])="false"
            (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete (optionSelected)='openDialog(getItem($event.option.value))' #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button mat-button (click)="searching = !searching">Cancel</button>
      </mat-toolbar>
      <!-- <div class="notifications">We upgraded our site! <a style="color: white" [href]="feedbackform" target="_blank"
          rel="noopener">Give feedback</a></div> -->
      <div class="groups" (sectionChange)="onSectionChange($event)">

        <router-outlet></router-outlet>
      </div>
      <footer class="p-t-32 m-t-36" *ngIf="(orderService.restaurant) as r">

        <div class="space-evenly">
          <div class="col-xs-12 col-md-4">
            <p class="text-uppercase font-weight-bold m-b-8 m-t-8">{{orderService.restaurant?.displayName}}</p>
            <hr class="purplehr m-b-8">
            <p class="m-b-8">{{r.address}}</p>
            <p class="m-b-8">Phone: {{r.phoneNum}}</p>
          </div>

          <div class="col-xs-12 col-md-4">
            <p class="text-uppercase font-weight-bold m-b-8 m-t-8">Useful Links</p>
            <hr class="purplehr m-b-8">

            <p class="col-12">
              <a class="a_color m-b-8"
                [routerLink]="'/' + this.activatedRoute.snapshot.paramMap.get('restaurantId')">Menu</a>
            </p>
            <p class="col-12">
              <a class="a_color m-b-8"
                [routerLink]="'/' +this.activatedRoute.snapshot.paramMap.get('restaurantId')+'/Terms-Of-Service'">Terms
                of
                Service</a>
            </p>
            <p class="col-12">
              <a class="a_color m-b-8"
                [routerLink]="'/' +this.activatedRoute.snapshot.paramMap.get('restaurantId')+'/privacy-policy'">Privacy
                Policy</a>
            </p>

          </div>
        </div>
        <br>
        <div class="space-evenly">
          <div class="col-12 text-center py-5 mx-auto">Powered By:
            <a class="a_color" href="https://inhouseorders.io/">Inhouse Orders</a>
          </div>
        </div>
        <br>
        <div class="space-evenly">
          <!-- Copyright -->
          <div class="col-12 text-center py-5 mx-auto">© 2019-2021 Copyright: Develop Denver LLC
          </div>
        </div>
        <br>
        <br>
      </footer>
    </div>
    <button aria-label="Go to Checkout Button" *ngIf="orderService.cartLength > 0 && !router.url.includes('checkout')"
      (click)='orderService.sortItems()' routerLink="checkout" class="myFabButton" mat-fab color="primary">
      <mat-icon>shopping_cart</mat-icon><span class="badge">{{orderService.cartLength}}</span>
    </button>
  </mat-sidenav-content>
</mat-sidenav-container>
<div *ngIf="!orderService.restaurant">
  <mat-card style="height: 100vh">
    <mat-spinner class="mx-auto mt-4 mb-4"></mat-spinner>
    <p class="w-100 mx-auto text-center mt-4">Loading Restaurant...</p>
  </mat-card>
</div>