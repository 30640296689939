import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-simpledialog',
  templateUrl: './simpledialog.component.html',
  styleUrls: ['./simpledialog.component.scss']
})
export class SimpledialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string, title: string , errorMessage:any}) { }

  ngOnInit(): void {
  }

}
